export default {
  // VALIDATION RULES MESSAGES
  'sh.vr.message.default': 'This value seems to be invalid.',
  'sh.vr.message.required': 'This field is required.',
  'sh.vr.message.required-phone': 'Phone number is required.',
  'sh.vr.message.too-long-phone': 'Phone length is too long.',
  'sh.vr.message.page-not-exist': 'This page doesn\'t exist.',
  'sh.vr.message.requiredEmail': 'Email address cannot be empty.',
  'sh.vr.message.address': 'Please enter a real address.',
  'sh.vr.message.card': 'Credit card isn\'t valid.',
  'sh.vr.message.city': 'Please enter a real city.',
  'sh.vr.message.domainUrl': 'Please enter a valid domain url.',
  'sh.vr.message.email': 'Please enter a real email.',
  'sh.vr.message.firstname': 'Please enter your first name.',
  'sh.vr.message.lastname': 'Please enter your real last name.',
  'sh.vr.message.name': 'Please enter your real name.',
  'sh.vr.message.subject': 'Please enter less than 256 characters.',
  'sh.vr.message.surname': 'Please enter a real surname.',
  'sh.vr.message.password': 'Please enter your password.',
  'sh.vr.message.phone': 'Please enter a real phone number.',
  'sh.vr.message.state': 'Please enter a real state.',
  'sh.vr.message.url': 'Please enter a real link.',
  'sh.vr.message.zip': 'Please enter a real code.',
  'sh.vr.message.twitter': 'Please enter a real link to twitter page.',
  'sh.vr.message.facebook': 'Please enter a real link to facebook page.',
  'sh.vr.message.number': 'Please enter real a number.',
  'sh.vr.message.currency': 'Please enter a real currency.',
  'sh.vr.message.maxlength_default':
    'Maximum length of this field is {{number}} characters.',
  'sh.vr.message.maxlength_password':
    'Maximum password length is {{number}} characters.',
  'sh.vr.message.maxlength_zip':
    'Maximum length of postal code is {{number}} characters.',
  'sh.vr.message.minlength_default':
    'Please enter at least {{number}} characters.',
  'sh.vr.message.youtube.video-not-exist':
    'This Youtube video doesn\'t exist.',
  'se.wf.form_layout_field-name_placeholder-surname': 'Enter your surname',
  'sh.vr.message.captchaError': 'Google reCaptcha verification failed, please try again.',

  // FORM
  'sh.wf.message.tooltip-text-copied': 'Promo code is copied',

  // MAP
  'se.wf.map_default-address': 'New York, Park Avenue',

  // PRODUCT PAGE
  'sh.wf.ecom_product_page.button-text-sold-out': 'Sold Out',
  'sh.wf.ecom_product_page.tooltip-text-out-of-stock': 'Out of stock',

  // PRODUCT WIDGET
  'sh.wf.ecom_product.button-text-checkout': 'Checkout',
  'sh.wf.ecom_product.button-text-view-details': 'View Details',
  'sh.wf.ecom_product.status-outOfStock': 'Sold Out',
  'sh.wf.ecom_product.on_sale': 'On Sale',
  'sh.wf.ecom_product.digital': 'Digital',
  'sh.wf.ecom_product.few_left': 'Few Left',
  'sh.wf.ecom_product.price_was': 'was',
  'sh.wf.ecom_product.save': 'Save',
  'sh.wf.ecom_product.buy_button_title': 'Buy Now',

  // ECOMMERCE CATALOGUE
  'sh.wf.ecom_catalog.placeholder_product_name': 'Sample product',
  'sh.wf.ecom_catalog.placeholder_buy_button': 'Buy Now',

  // ECOMMERCE CATALOGUE PAGINATION
  'sh.wf.ecom_catalog.pagination_of': 'of',
  'sh.wf.ecom_catalog.pagination_items': 'items',
  'sh.wf.ecom_catalog.pagination_prev': 'Prev',
  'sh.wf.ecom_catalog.pagination_back': 'Back',
  'sh.wf.ecom_catalog.pagination_next': 'Next',

  'sh.wf.ecom_store_suspended_title': 'Error',
  'sh.wf.ecom_store_suspended_description':
    'This store is currently offline, please contact the Site Administrator for further assistance.',

  // INSTAGRAM WIDGET
  'sh.wf.instagram_exist_err_text':
    'THIS INSTAGRAM ACCOUNT IS TEMPORARY UNAVAILABLE',
  'sh.wf.instagram_exist_err_sub':
    'This account is temporary unavailable or doesn’t exist anymore.',
  'sh.wf.instagram_empty_err_text': 'THIS INSTAGRAM ACCOUNT IS EMPTY',
  'sh.wf.instagram_empty_err_sub':
    'Add photos to your account or connect another valid account with photos.',

  // #region BLOG_POSTS
  'sh.wf.blog_list_read-more': 'Read More',
  // #endregion BLOG_POSTS
};
