import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import ResizeObserver from 'resize-observer-polyfill';

import booking from '../../booking';
import { checkServicePage } from '../../booking/utils';
import { DEFAULT_STORE_URL } from '../../ecommerce/ecwid/native/router/constants';
import {
  isCartPage,
  isProductPage,
} from '../../ecommerce/ecwid/native/router/utils/index';
import browser from '../../helpers/browser';
import getStateValue from '../../helpers/getStateValue';
import { subscribeToDeviceChanging } from '../../observer/deviceObserver';
import { subscribeToDebouncedResizeChanging } from '../../observer/resizeObserver';
import dom from '../../wrapper/DomWrapper';

import { getSizeSVGFromViewBox } from './utils/getSizeSVGFromViewBox';
import {
  CART_CLASS,
  COLLAPSED_CLASS,
  CROP_CLASS,
  CROP_CONTENT_CLASS,
  HEADER_CLASS,
  HEADER_LAYOUT_WRAPPER,
  HEADER_WRAPPER,
  LOGO_CLASS,
  LOGO_TEXT_CLASS,
  MENU_ACTIVE,
  MENU_INNER_PAGE_CLASS,
  MODE_COVER_CLASS,
  NAV_BOX_CLASS,
  S_WRAPPER_NAV_CLASS,
} from './constants';

class Header {
  constructor(elHeader, type, headerManager) {
    this.elHeader = elHeader;
    this.headerType = type;
    this.headerManager = headerManager;
    this.elMainHeader = headerManager?.elMainHeader;
    this.settings = headerManager?.settings;
  }

  init = () => {
    const { burger } = this.settings;

    this.layoutsSize = 0;
    this.logoWidth = 0;
    this.pageHref = dom.window?.location?.href;
    this.elNav = dom.getElement(NAV_BOX_CLASS);

    this.onLogoLoad();

    if (!burger) this.initNavObserver();

    if (this.checkCartOrProductPage()
      || this.checkServicePage()) this.constructor.addClassNameToNavItemActive(MENU_INNER_PAGE_CLASS);
  };

  initNavObserver = () => {
    if (!this.elNav) return;

    // Calculate correct collapsed after fonts loaded
    this.collapseResizeObserver = new ResizeObserver(debounce(() => {
      this.connectCollapse();
    }, 200));

    this.collapseResizeObserver.observe(this.elNav);
  };

  destroyNavObserver = () => {
    if (!this.collapseResizeObserver) return;

    this.collapseResizeObserver.unobserve(dom.getElement(NAV_BOX_CLASS));
    this.collapseResizeObserver = null;
  };

  onLogoLoad = () => {
    const elHeaderWrapper = dom.getElement(HEADER_WRAPPER, this.elHeader);
    const elHeaderLogo = dom.getElement(`.${LOGO_CLASS}`, elHeaderWrapper);
    const elLogoImg = dom.getElement('img', elHeaderLogo);

    if (!elLogoImg) return;

    const elLogoParent = elLogoImg.parentNode;
    const { zoom } = elLogoParent.dataset;
    const { style: { transform }, complete } = elLogoImg;

    this.logoZoom = zoom;
    this.transform = transform;

    if (complete) {
      this.handleLogoLoaded();
    } else {
      elLogoImg.addEventListener('load', () => {
        this.handleLogoLoaded();
      });
    }
  };

  handleLogoLoaded = async () => {
    const { burger } = this.settings;

    await this.changeSize();

    if (!burger) {
      this.collapse();
    }
  };

  connectCollapse = async () => {
    this.elLayoutsWithoutLogo = dom.getCollection(`${HEADER_LAYOUT_WRAPPER}:not(.${LOGO_CLASS})`, this.elHeader);

    if (isEmpty(this.elLayoutsWithoutLogo) || (this.elLayoutsWithoutLogo.length === 1
        && dom.hasClass(this.elLayoutsWithoutLogo[0], CART_CLASS))) {
      return;
    }

    for (let i = 0; i <= this.elLayoutsWithoutLogo.length; i += 1) {
      if (this.elLayoutsWithoutLogo[i]) {
        const nav = dom.getElement(NAV_BOX_CLASS, this.elLayoutsWithoutLogo[i]);
        // in IE container doesn`t stretch, so calculated child inner width
        const navForIE = dom.getElement(S_WRAPPER_NAV_CLASS, nav);

        if (nav) {
          const navWidth = !browser.isIe()
            ? dom.getElementWidth(nav)
            : navForIE.clientWidth;

          this.layoutsSize += navWidth;
        } else {
          const [currentElement] = this.elLayoutsWithoutLogo[i].children;

          this.layoutsSize += dom.getElementWidth(currentElement);
        }
      }
    }

    // This logic for IE
    // ToDO: IE
    if (browser.isIe()) await this.addLogoWidthInIe();

    await this.changeSize();

    this.collapse(this.headerType);
    subscribeToDeviceChanging(this.elHeader, this.changeSize);
    subscribeToDebouncedResizeChanging(this.elHeader, this.collapse);
    this.destroyNavObserver();
  };

  changeSize = async () => {
    const elHeaderWrapper = dom.getElement(HEADER_WRAPPER, this.elHeader);

    this.elHeaderLogo = dom.getElement(`.${LOGO_CLASS}`, elHeaderWrapper);
    const elLogoImg = dom.getElement('img', this.elHeaderLogo);
    const elLogoText = dom.getElement(LOGO_TEXT_CLASS, this.elHeaderLogo);

    if (!elLogoImg && !elLogoText) return;

    let width;

    if (elLogoImg) {
      width = await this.getWidthForLogoImage(elLogoImg);
    } else if (elLogoText) {
      width = elLogoText.offsetWidth;
    }

    if (!width || this.logoWidth === width) return;

    this.layoutsSize -= this.logoWidth;
    this.logoWidth = width;
    this.layoutsSize += width;
  };

  getWidthForLogoImage = async (elLogoImg) => {
    const elLogoWrap = dom.getElement(CROP_CLASS, this.elHeaderLogo);
    const elLogoCrop = dom.getElement(MODE_COVER_CLASS, this.elHeaderLogo);

    // This logic for IE
    // ToDO: IE
    const {
      offsetHeight: height,
      offsetWidth,
    } = elLogoWrap;
    let {
      naturalWidth,
      naturalHeight,
      offsetHeight: heightForIE,
      offsetWidth: widthForIE,
    } = elLogoImg;

    if (!naturalHeight || !naturalWidth) {
      const { width: widthSVG, height: heightSVG } = await getSizeSVGFromViewBox(elLogoImg);

      naturalWidth = widthSVG;
      naturalHeight = heightSVG;
      widthForIE = widthSVG;
      heightForIE = heightSVG;
    }

    if (!naturalHeight) return null;

    let width = !browser.isIe()
      ? Math.floor((naturalWidth / naturalHeight) * height)
      : Math.floor((widthForIE / heightForIE) * height);

    if (elLogoCrop) {
      const { style: { zoom } } = elLogoImg;
      const elLogoCropContent = dom.getElement(CROP_CONTENT_CLASS, this.elHeaderLogo);
      const { offsetWidth: maxWidth } = elLogoCropContent;

      width = maxWidth;
      const isMozilla = browser.isMozilla();
      let stylesForUpdate;

      if (this.logoZoom) {
        if (width === 180 && (this.logoZoom / 2) !== +zoom) {
          stylesForUpdate = isMozilla
            ? { transform: `${this.transform} scale(${this.logoZoom / 2})` }
            : { zoom: this.logoZoom / 2 };

          dom.updateStyle(elLogoImg, stylesForUpdate);
        } else if (width === 360 && this.logoZoom !== zoom) {
          stylesForUpdate = isMozilla
            ? { transform: `${this.transform} scale(${this.logoZoom})` }
            : { zoom: this.logoZoom };

          dom.updateStyle(elLogoImg, stylesForUpdate);
        }
      }
    } else {
      const ratio = offsetWidth / height;
      const maxWidth = (browser.isIe() ? heightForIE : naturalHeight) * ratio;

      if ((browser.isIe() ? widthForIE : naturalWidth) > maxWidth) {
        dom.updateStyle(elLogoImg, {
          minWidth: '100%',
          minHeight: '0',
        });
      } else {
        dom.updateStyle(elLogoImg, {
          minWidth: '0',
          minHeight: '100%',
        });
      }
    }

    if (width >= 360) width = 360;

    dom.updateStyle(elLogoWrap, {
      width: `${width}px`,
    });

    return width;
  };

  // eslint-disable-next-line promise/param-names
  addLogoWidthInIe = () => new Promise((resolved) => {
    const elHeaderLogo = dom.getElement(`.${LOGO_CLASS}`, this.elHeader);
    const elLogoWrap = dom.getElement(CROP_CLASS, this.elHeaderLogo);
    const elLogoImg = dom.getElement('img', elHeaderLogo);

    if (!elLogoImg) return;

    const image = new Image();

    image.onload = () => {
      image.style.opacity = '0';
      image.style.position = 'fixed';
      dom.document.body.append(image);
      const {
        offsetWidth: widthForIE,
        offsetHeight: heightForIE,
      } = image;
      const { offsetHeight: height } = elLogoWrap;

      const width = Math.floor((widthForIE / heightForIE) * height);

      dom.updateStyle(elLogoWrap, { width: `${width}px` });

      image.remove();
      resolved();
    };

    image.src = elLogoImg.src;
  });

  collapse = () => {
    const elHeaderWrapper = dom.getElement(HEADER_WRAPPER, this.elMainHeader);
    const headerWrapperWidth = dom.getElementWidth(elHeaderWrapper) - 30;
    const elCommonHeader = dom.getElement(HEADER_CLASS);
    const isCollapsed = dom.hasClass(elCommonHeader, COLLAPSED_CLASS);

    if (this.layoutsSize > headerWrapperWidth) {
      if (isCollapsed) return;

      this.headerManager.collapse(this.headerType);
    } else {
      if (!isCollapsed) return;

      this.headerManager.uncollapse(this.headerType);
    }
  };

  checkCartOrProductPage = () => {
    const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);

    return isCartPage(this.pageHref, storeUrl) || isProductPage(this.pageHref, storeUrl);
  };

  checkServicePage = () => {
    const { bookingUrl } = booking.provider;

    return checkServicePage(this.pageHref, bookingUrl);
  };

  static addClassNameToNavItemActive = (className = '') => {
    const elNavItem = dom.getElement(MENU_ACTIVE);

    dom.addClass(elNavItem, className);
  };
}

export default Header;
