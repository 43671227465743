import format from 'date-fns/format';

import getBlogImagesResize from '../../../../../helpers/getBlogImagesResize/getBlogImagesResize';
import { LAYOUT_FEED } from '../../../../components/ItemsView/constants';
import { DEVICE_SIZES } from '../../../../components/Photo/constants';
import { convertSrcToCorrect } from '../../../../helper/urls';
import { POST_IMAGE_SELECTOR } from '../../../constants';
import getStateValue from '../../../helpers/getStateValue';
import PostImage from '../../Blog/PostImage/PostImage';
import { getPictureSource, prepareImages } from '../../Blog/utils';

import {
  addGridItemImages,
  mountBlogFeedInView,
  mountItemsInView,
  mountWidgetsInView,
} from './utils';

const { BLOG_STATIC_DIR } = require('../../../../../helpers/getBlogImagesResizeUrl/constants');

const afterInit = (elContainer) => {
  mountWidgetsInView(elContainer);
};

const preRender = (item, index, elItemsContainer, {
  source,
} = {}) => {
  const { previewImageUrl, images } = item || {};
  const arrImages = prepareImages(images);
  const previewImage = arrImages.find(({ isPreview }) => !!isPreview);

  // for Safari replace(/ /g, 'T')
  const datePublished = format(new Date(item.datePublished.replace(/ /g, 'T')), 'dd MMM yyyy');
  const { layoutSettings } = source || {};
  const { isStaticImagesPublished } = item;
  const apiUrls = getStateValue('apiUrls');
  const staticData = {
    isStaticPublished: isStaticImagesPublished,
    staticDir: `/${BLOG_STATIC_DIR}`,
  };
  const originalSrc = convertSrcToCorrect(previewImageUrl, apiUrls);
  const [imagesResize] = getBlogImagesResize(layoutSettings, [previewImage || {}]);
  const isValidImage = !!imagesResize?.hash;
  const convertedSrc = isValidImage ? convertSrcToCorrect(previewImageUrl, apiUrls, staticData) : originalSrc;

  const { previewTransform: transform, ...rest } = imagesResize || {};
  const nextPreviewImage = {
    ...rest,
    transform,
  };

  const preview = {
    url: convertedSrc,
    source: getPictureSource(convertedSrc, nextPreviewImage, DEVICE_SIZES, {
      apiUrls,
      staticData,
      originalSrc,
      isValidImage,
    }),
  };

  return {
    ...item,
    datePublished,
    previewImageUrl: convertedSrc,
    preview,
    widthPreview: !!preview.url && !!preview.source,
  };
};

const postRender = (element, data) => {
  addGridItemImages(element, data);

  return element;
};

// eslint-disable-next-line func-style
function didMount(elContainer) { // don't use arrow func, this func called with context
  const { layoutSettings } = this.settings || {};
  const isFeed = layoutSettings?.reaLayoutType === LAYOUT_FEED;

  (new PostImage(POST_IMAGE_SELECTOR)).init(elContainer);

  if (isFeed) {
    mountBlogFeedInView(elContainer);

    return;
  }

  mountItemsInView(elContainer);
}

export default {
  afterInit,
  preRender,
  postRender,
  didMount,
};
